<script>
	import { onMount } from 'svelte';

	import SETTINGS from './settings';
	import http from './store/http.js'

	import Icons from './components/Icons.svelte';
	import Header from './components/Header.svelte';
	import ProgramListItem from './components/ProgramListItem.svelte';
	import Program from './components/Program.svelte';
	import CreaTuPlan from "./components/CreaTuPlan.svelte";
	import { currentProgram } from "./store/app";

	export let apikey;
	export let useruuid;

	let programs = http([]);
	let isBehind = false;
	let crea_plan = false;
	let has_auto = false;

	const init = async () => {
		SETTINGS.API.KEY = apikey;
		SETTINGS.USER.UUID = useruuid;

		if(apikey && useruuid){
			await fetchPrograms();
		}else{
			alert('You are not authorised here');
		}
	};

	const fetchPrograms = async () => {
		await programs.get('/users/' + SETTINGS.USER.UUID + '/programs');
	};

	const refreshPrograms = async () => {
		await fetchPrograms();
		isBehind = false;
		crea_plan = false;
	};

	$: has_auto = $programs.data && $programs.data.some(program => program.auto);

	currentProgram.subscribe(async value => {
		if(!value){
			isBehind = false;
		}
	});

	// COMPONENT LIFECYCLE //////////////////////////////////////////////////
	onMount(() => {
		init();
	});
</script>

<!-- {@html JSON.stringify($APP, null, 10)} -->

<div class="wrapper">
	<Icons />

	{#if apikey}
		<Header modal_opened={crea_plan} on:close_modal={() => crea_plan = false}>Entrenamiento</Header>

		<main>
			<section class="home" class:is-behind={isBehind}>
				<div class="hero">
					<h1 on:click={fetchPrograms}>Tus Planes de Entrenamiento</h1>
				</div>

				<div class="programs">
					{#if $programs.data && $programs.data.length}
						<ul>
							{#each $programs.data as program}
								<ProgramListItem program={program} />
							{/each}
						</ul>
					{:else if $programs.loading}
						<div class="loading">Loading...</div>
					{/if}
				</div>

				{#if !$programs.loading}
					<div class="access-crea-plan">
						{#if has_auto}
							<h3>Cambiar tu plan</h3>
							<p>Responde el cuestionario con tus objetivos y crea tu plan personal</p>
							<button class="btn" on:click={() => crea_plan = true}>CAMBIAR PLAN</button>
						{:else}
							<h3>Crea tu plan</h3>
							<p>Responde el cuestionario con tus objetivos y crea tu plan personal</p>
							<button class="btn" on:click={() => crea_plan = true}>EMPIEZA YA!</button>
						{/if}
					</div>
				{/if}
			</section>

			{#if crea_plan}<CreaTuPlan on:api_request_finished={() => isBehind = true} on:refresh_programs={refreshPrograms} />{/if}
			<Program on:api_request_finished={() => isBehind = true} />
		</main>
	{:else}
		<div class="no-auth">:(</div>
	{/if}
</div>


<style lang="scss">:global(#alvarium-trainings-app) {
  --font-family: 'Barlow', "Helvetica Neue", Arial, sans-serif;
  --line-height: 1.5;
  --base-font-size: 13px;
  --color: 255, 255, 255;
  --color-accent: 255, 76, 0;
  --color-success: 143, 221, 76;
  --color-material: 189, 238, 58;
  --color-warmup: 161, 196, 253;
  --bg-image: url(../assets/img/bg.jpg);
  --bg-color: rgba(35, 35, 35, .7);
  --bg-blur: 33px;
  --header-height: 60px;
  max-width: 860px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-family: var(--font-family);
  font-size: var(--base-font-size);
  font-weight: 400;
  line-height: var(--line-height);
  letter-spacing: 0.87px;
  color: rgba(var(--color), 1);
  background: var(--bg-image) right center no-repeat #000;
  background-size: cover;
  overflow: hidden; }

:global(h1) {
  font-size: 1.9em;
  font-weight: 500;
  letter-spacing: 1px; }

:global(h2) {
  font-size: 1.8em;
  font-weight: 500;
  letter-spacing: 1px; }

:global(h3) {
  font-size: 1.7em;
  font-weight: 500;
  letter-spacing: 1px; }

:global(h4) {
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.87px; }

:global(.btn) {
  display: inline-block;
  border: 2px solid #FFFFFF;
  border-radius: 26.5px;
  background: transparent;
  padding: 9px 10px;
  min-width: 200px;
  color: #fff; }

.wrapper {
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  -webkit-backdrop-filter: blur(var(--bg-blur));
          backdrop-filter: blur(var(--bg-blur)); }

main {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-top: 70px;
  padding-bottom: 66px;
  box-sizing: border-box; }

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 20vh;
  padding: 40px 20px; }

.home {
  transition: opacity 300ms ease-in-out; }
  .home.is-behind {
    opacity: 0; }

.programs ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 15vh;
  padding: 40px; }
  .hero h1 {
    margin: 0 0 20px 0;
    line-height: 1.2; }

.no-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 4em;
  font-weight: bold; }

.access-crea-plan {
  text-align: center;
  max-width: 250px;
  margin: 40px auto; }
  .access-crea-plan p {
    font-size: 1.4em;
    margin-bottom: 20px; }
  .access-crea-plan h3 {
    color: rgba(var(--color-accent), 1);
    margin-bottom: 7px; }</style>
