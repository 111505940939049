<script>
	import { currentProgram } from '../store/app.js';

	export let program;

	const handleClick = () => {
		currentProgram.set(program);
	};
</script>


<li class="item" on:click={handleClick} style="background-image: url({program.image || ''});">
	<div class="darken"></div>
	<h3>{program.name}</h3>
	<p>{program.auto ? 'Autoasignado' : 'Asignado'}</p>
</li>


<style lang="scss">.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
  padding: 44% 20px 20px 20px;
  margin-bottom: 5px;
  background-color: #353536;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.darken {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.34);
  z-index: 0; }

h3 {
  position: relative;
  margin: 0; }

p {
  margin: 0; }</style>
