<script>
	import { fly } from 'svelte/transition';
	import { createEventDispatcher } from "svelte";

	import Loading from './Loading.svelte';

	import { currentProgram, currentSession, currentCircuit, currentExercise } from '../store/app.js';

	const dispatch = createEventDispatcher();

	export let modal_opened = false;

	const handleClick = () => {
		if(modal_opened) dispatch('close_modal');
		else if($currentExercise) currentExercise.set(null);
		else if($currentCircuit) currentCircuit.set(null);
		else if($currentSession) currentSession.set(null);
		else if($currentProgram) currentProgram.set(null);
		else window.location = 'https://enjoy.es/app-indice-activate-en-casa/';
	};
</script>


<header class:modal_opened>
	<button type="button" class="button"
			on:click={handleClick}
			transition:fly="{{ x: -100, duration: 400 }}">
		<svg width="20" height="25"><use xlink:href="#arrow"></use></svg>
	</button>

	{#if !modal_opened}
		{#if $currentSession}
			<h3>{$currentProgram.name}</h3>
		{:else if $currentProgram}
			<h3>Planes</h3>
		{:else}
			<h3><slot></slot></h3>
		{/if}
	{/if}

	<Loading />
</header>


<style lang="scss">header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 20px 0 20px;
  height: var(--header-height);
  -webkit-backdrop-filter: blur(var(--bg-blur));
          backdrop-filter: blur(var(--bg-blur));
  background: rgba(35, 35, 35, 0.8);
  z-index: 10;
  vertical-align: center; }
  header.modal_opened {
    background: #0B0603; }

h3 {
  display: block;
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  box-shadow: none;
  outline: none;
  margin-right: 7px; }</style>
