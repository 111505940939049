<script>
	import { fly } from 'svelte/transition';

	import { currentProgram, currentSession, currentCircuit, currentExercise } from '../store/app.js';
	import http from '../store/http.js'
	import SETTINGS from '../settings';

	import ExerciseListItem from './ExerciseListItem.svelte';
	import CircuitListItem from './CircuitListItem.svelte';
	import Exercise from './Exercise.svelte';
	import Circuit from "./Circuit.svelte";

	let session = http({});
	let isBehind = false;
	let w;

	currentSession.subscribe(async value => {
		if(value && $currentProgram) await session.get('/users/' + SETTINGS.USER.UUID + '/programs/' + $currentProgram.id + '/sessions/' + value.id);
		else session.reset();
	});

	currentExercise.subscribe(async value => {
		if(!value){
			if(!$currentCircuit) isBehind = false;
			if($currentSession) await session.get('/users/' + SETTINGS.USER.UUID + '/programs/' + $currentProgram.id + '/sessions/' + $currentSession.id);
		}
	});

	currentCircuit.subscribe(value => {
		if(!value) isBehind = false;
	});
</script>


<svelte:window bind:innerWidth={w}/>

{#if $session.data}
	<section class="session"
			 class:is-behind={isBehind}
			 class:is-material={$currentSession.is_material}
			 transition:fly="{{ x: w, opacity: 1, duration: 400 }}">

		<div class="wrapper">
			<div class="header" style="background-image: url({$currentProgram.image || ''});">
				<div class="darken"></div>

				<div class="title">
					<h1>{$currentProgram.name}</h1>
					<h4>{$currentSession.name}</h4>
				</div>
			</div>

			{#if !$currentSession.is_material}
				<div class="meta">
					<div>{$currentSession.num_exercises}  {$currentSession.num_exercises > 1 ? 'ejercicios' : 'ejercicio'}</div>
					<div>{$currentSession.info1 || ''}</div>
				</div>
			{/if}

			<div class="exercises">
				<ul>
					{#each $session.data as execuit}
						{#if execuit.is_circuit}
							<CircuitListItem circuit={execuit} isMaterial={$currentSession.is_material} />
						{:else}
							<ExerciseListItem exercise={execuit} isMaterial={$currentSession.is_material} />
						{/if}
					{/each}
				</ul>
			</div>
		</div>
	</section>

	<Circuit on:api_request_finished={() => isBehind = true} />

	{#if !$currentCircuit}
		<Exercise on:api_request_finished={() => isBehind = true} />
	{/if}
{/if}


<style lang="scss">.session {
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  overflow-y: auto;
  transition: transform 200ms ease-in-out; }
  .session.is-behind {
    transform: translateX(-101vw); }
  .session.is-material .header h4 {
    color: rgba(var(--color-material), 1); }
  .session.is-material .exercises {
    background: transparent; }

.wrapper {
  min-height: 100%; }

.darken {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.34);
  z-index: 0; }

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 18vh;
  padding: 20px 40px;
  background-color: #353536;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center; }
  .header .title {
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto; }
  .header h1 {
    position: relative;
    margin: 0; }
  .header h4 {
    position: relative;
    margin: 10px 0 0 0;
    font-size: 1.2em;
    text-transform: uppercase;
    color: rgba(var(--color-accent), 1); }

.meta {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: left;
  font-size: 1.2em; }
  .meta div {
    padding: 20px; }
    .meta div:nth-child(odd) {
      border-right: 1px solid rgba(255, 255, 255, 0.3); }

.exercises {
  background: #fff;
  color: #333333; }
  .exercises ul {
    list-style: none;
    margin: 0;
    padding: 0; }</style>
