<script>
	import { fly } from 'svelte/transition';

	import { currentCircuit, currentProgram, currentSession, currentExercise, circuitProgress } from '../store/app.js';
	import http from '../store/http.js'
	import SETTINGS from "../settings";
	import { parseVideo } from '../utils';
	import "../../node_modules/plyr/src/sass/plyr.scss";
	import { onDestroy } from "svelte";
	import Plyr from "plyr";

	let exerciseContainer;
	let exercise = http({});
	let progress = http({});
	let w;
	let player;
	let plyrDiv;
	let displayData = {
		name: null,
		video: null,
		circuitName: null,
		series: 0,
		reps: 0,
		description: null,
		intensity: null,
		comments: null
	};
	let isVideoPlaying = false;
	let showVideoCover = false;

	$: if($exercise.data){
		showVideoCover = false;
		isVideoPlaying = false;
		displayData = {
			name: $exercise.data.exercise.name,
			image: $exercise.data.exercise.image,
			video: $exercise.data.exercise.video ? parseVideo($exercise.data.exercise.video) : null,
			link: $exercise.data.exercise.link,
			circuitName: $currentCircuit ? ' ' + $currentCircuit.name + ' / ' : '',
			series: $exercise.data.series ? $exercise.data.series : $exercise.data.exercise.series,
			reps: $exercise.data.reps ? $exercise.data.reps : $exercise.data.exercise.reps,
			description: $exercise.data.description ? $exercise.data.description : $exercise.data.exercise.description,
			intensity: $exercise.data.intensity ? $exercise.data.intensity : $exercise.data.exercise.intensity,
			comments: $exercise.data.comments ? $exercise.data.comments : $exercise.data.exercise.comments,
		}

		if(player){
			player.source = {
				type: 'video',
				sources: [
					{
						src: displayData.video.id,
						provider: displayData.video.type,
					},
				],
			};
		}
	}else{
		showVideoCover = false;
		isVideoPlaying = false;
		displayData = {
			name: null,
			video: null,
			link: null,
			circuitName: null,
			series: 0,
			reps: 0,
			description: null,
			intensity: null,
			comments: null
		};
	}

	$: if(plyrDiv && !player){
		player = new Plyr(plyrDiv, {
			controls: ['fullscreen']
		});

		player.on('playing', e => {
			showVideoCover = true;
			isVideoPlaying = true;
		});

		player.on('pause', e => {
			isVideoPlaying = false;
		});
	}

	currentExercise.subscribe(async value => {
		if(value) await exercise.get('/users/' + SETTINGS.USER.UUID + '/exercises/' + value.session_exercise_id);
		else exercise.reset();
	});

	const handlePrevClick = async () => {
		if($currentExercise.circuit_id){ // Si l'exercici pertany a un circuit...
			const temp = $circuitProgress;

			if(temp && temp[1] - 1 >= 0){
				circuitProgress.set([temp[0], temp[1] - 1]);
				currentExercise.set(temp[0][temp[1] - 1]);
			}else{
				await progress.post('/users/' + SETTINGS.USER.UUID + '/progress', {session_exercise_id: $currentExercise.circuit_id});
				currentExercise.set(null);
				currentCircuit.set(null);
			}
		}else{ // Si es un exercici individual...
			await progress.post('/users/' + SETTINGS.USER.UUID + '/progress', {session_exercise_id: $currentExercise.session_exercise_id});

			if($progress.data){
				if($progress.data.prev_exercise){
					if($progress.data.prev_exercise.is_circuit){
						currentExercise.set(null);
						currentCircuit.set($progress.data.prev_exercise);
					}else{
						currentExercise.set($progress.data.prev_exercise);
					}
				}else{
					currentExercise.set(null);
					currentCircuit.set(null);
				}
			}
		}

		exerciseContainer.scrollTo(0, 0);
	};

	const handleNextClick = async () => {
		if($currentExercise.circuit_id){ // Si l'exercici pertany a un circuit...
			const temp = $circuitProgress;

			if(temp && temp[1] + 1 < temp[0].length){
				circuitProgress.set([temp[0], temp[1] + 1]);
				currentExercise.set(temp[0][temp[1] + 1]);
			}else{
				await progress.post('/users/' + SETTINGS.USER.UUID + '/progress', {session_exercise_id: $currentExercise.circuit_id});
				currentExercise.set(null);
				currentCircuit.set(null);
			}
		}else{ // Si es un exercici individual...
			await progress.post('/users/' + SETTINGS.USER.UUID + '/progress', {session_exercise_id: $currentExercise.session_exercise_id});

			if($progress.data){
				if($progress.data.next_exercise){
					if($progress.data.next_exercise.is_circuit){
						currentExercise.set(null);
						currentCircuit.set($progress.data.next_exercise);
					}else{
						currentExercise.set($progress.data.next_exercise);
					}
				}else{
					currentExercise.set(null);
					currentCircuit.set(null);
				}
			}
		}

		exerciseContainer.scrollTo(0, 0);
	};

	const handleVideoCoverClick = (e) => {
		e.stopPropagation();
		player.togglePlay();
	};

	onDestroy(() => {
		if(player) player.destroy();
	});
</script>

<svelte:window bind:innerWidth={w}/>

{#if $exercise.data}
	<section bind:this={exerciseContainer}
			 class="exercise"
			 class:is-warmup={$exercise.data.is_warmup}
			 class:is-material={$currentSession.is_material}
			 transition:fly="{{ x: w, opacity:1, duration: 400 }}">

		<div class="wrapper">
			<div class="header">
				<h3><span>{$currentSession.name} /{displayData.circuitName}</span> {displayData.name}</h3>
			</div>

			{#if displayData.video && displayData.video.id}
				<div class="video">
					{#if displayData.video.type === 'vimeo'}
						<div bind:this={plyrDiv} class="plyr__video-embed" style="--plyr-color-main: rgba(0, 0, 0, .4)">
							<iframe allowfullscreen allowtransparency title={displayData.name}
								src="https://player.vimeo.com/video/{displayData.video.id}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media"></iframe>
						</div>
					{:else if displayData.video.type === 'youtube'}
						<div bind:this={plyrDiv} class="plyr__video-embed" style="--plyr-color-main: rgba(0, 0, 0, .4)">
							<iframe allowfullscreen allowtransparency allow="autoplay" title={displayData.name}
									src="https://www.youtube.com/embed/{displayData.video.id}?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1"></iframe>
						</div>
					{/if}
					{#if !isVideoPlaying}<div class="big-play"><svg focusable="false" viewBox="0 0 18 18"><path fill="currentColor" d="M15.562 8.1L3.87.225c-.818-.562-1.87 0-1.87.9v15.75c0 .9 1.052 1.462 1.87.9L15.563 9.9c.584-.45.584-1.35 0-1.8z"></path></svg></div>{/if}
					{#if showVideoCover}<div class="video-cover" on:click={handleVideoCoverClick}></div>{/if}
				</div>
			{:else if displayData.link}
				<div class="link" style="background-image: url({displayData.image || ''});">
					<div class="darken"></div>
				</div>

				<a href={displayData.link} class="btn_open">ABRIR</a>
			{/if}

			{#if (displayData.video && displayData.video.id) || !displayData.link}
				{#if (displayData.series || displayData.reps) && !$currentSession.is_material}
					<div class="info reps">
						{#if displayData.series && displayData.reps}
							<strong>{displayData.series} x {@html displayData.reps}</strong>
						{:else if displayData.series}
							<strong>{displayData.series} vueltas</strong>
						{:else}
							<strong>{@html displayData.reps.replace(/(?:\r\n|\r|\n)/g, '<br>')}</strong>
						{/if}
					</div>
				{/if}
			{/if}

			{#if displayData.description}
				<div class="info description">
					{@html displayData.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}
				</div>
			{/if}

			{#if displayData.intensity}
				<div class="info intensity">
					<strong>Carácter del esfuerzo</strong><br>
					{@html displayData.intensity.replace(/(?:\r\n|\r|\n)/g, '<br>')}
				</div>
			{/if}

			{#if displayData.comments}
				<div class="info comments">
					{#if !$currentSession.is_material}
						<strong>Observaciones</strong><br>
					{/if}

					{@html displayData.comments.replace(/(?:\r\n|\r|\n)/g, '<br>')}
				</div>
			{/if}

			<div class="nav">
				<div class="bg"></div>
				<button type="button" class="prev" on:click|preventDefault={handlePrevClick}><svg width="24" height="24"><use xlink:href="#arrow"></use></svg> ANTERIOR</button>
				<button type="button" class="next" on:click|preventDefault={handleNextClick}>SIGUIENTE <svg width="24" height="24"><use xlink:href="#arrow"></use></svg></button>
			</div>
		</div>
	</section>
{/if}


<style lang="scss">.exercise {
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  overflow-y: auto; }
  .exercise.is-warmup .header h3 {
    color: rgba(var(--color-warmup), 1); }
  .exercise.is-warmup .nav .bg {
    background: rgba(var(--color-warmup), 1);
    background: linear-gradient(90deg, #A1C4FC 0%, #4685E9 100%); }
  .exercise.is-material .header h3 {
    color: rgba(var(--color-material), 1); }
  .exercise.is-material .nav .bg {
    background: rgba(var(--color-material), 1);
    background: linear-gradient(90deg, #A2BD31 0%, #B4EC51 100%); }
  .exercise.is-material .info.comments {
    text-align: left; }

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.header {
  padding: 20px 40px;
  background: #555;
  text-align: center; }
  .header h3 {
    margin: 0;
    font-size: 1em;
    text-transform: uppercase;
    color: rgba(var(--color-accent), 1); }
    .header h3 span {
      color: #fff; }

.video {
  position: relative;
  background-color: #353536;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%; }
  .video .big-play {
    background: rgba(0, 0, 0, 0.4);
    border: 0;
    border-radius: 100%;
    color: #fff;
    left: 50%;
    opacity: 0.9;
    padding: 15px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    z-index: 2;
    pointer-events: none; }
    .video .big-play svg {
      width: 25px; }
  .video .video-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 18vh;
  padding: 20px 40px;
  background-color: #353536;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center; }

.btn_open {
  display: block;
  margin: 20px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding: 20px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 1px; }
  .btn_open:active {
    background: white;
    color: #131313; }

.info {
  padding: 25px 20px;
  text-align: center; }
  .info.reps {
    font-size: 1.2em; }
  .info.description {
    font-size: 1.2em;
    background: rgba(0, 0, 0, 0.5); }
  .info.comments {
    background: rgba(0, 0, 0, 0.5); }
  .info strong {
    font-size: 1.2em;
    font-weight: bold; }

.nav {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background: #000; }
  .nav .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: .7;
    background: rgba(var(--color-accent), 0.7);
    background: linear-gradient(90deg, #FF5700 0%, #FE1C48 100%); }
  .nav button {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.6px;
    padding: 17px;
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
    border: none;
    box-shadow: none;
    text-shadow: none;
    white-space: nowrap; }
  .nav .prev {
    justify-content: flex-start; }
    .nav .prev svg {
      margin-right: 10px; }
  .nav .next {
    justify-content: flex-end; }
    .nav .next svg {
      margin-left: 10px;
      transform: rotate(180deg); }</style>
