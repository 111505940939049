<script>
	import { currentExercise } from '../store/app.js';

	export let exercise;
	export let isMaterial = false;

	const handleClick = () => {
		currentExercise.set(exercise);
	};
</script>


<li on:click={handleClick} class:is-material={isMaterial} class:is-warmup={exercise.is_warmup}>
	<div class="listitem-left">
		{#if exercise.done}
			<svg width="26" height="26"><use xlink:href="#checked"></use></svg>
		{:else}
			<svg width="26" height="26"><use xlink:href="#unchecked"></use></svg>
		{/if}
	</div>
	<div class="listitem-center">
		<div class="name">{exercise.exercise.name}</div>
	</div>
	<div class="listitem-right"><svg width="20" height="20"><use xlink:href="#arrow"></use></svg></div>
</li>


<style lang="scss">li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background: #fff; }
  li:nth-child(odd) {
    background: #eee; }
  li.is-warmup {
    background: rgba(var(--color-warmup), 1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  li.is-material {
    background: transparent;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    li.is-material .listitem-left {
      border-right: 1px solid rgba(255, 255, 255, 0.3); }
    li.is-material .listitem-center {
      color: rgba(var(--color-material), 1); }
  li .listitem-left {
    padding: 7px 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.3); }
  li .listitem-center {
    flex: 1;
    padding: 7px 15px; }
  li .listitem-right {
    padding: 7px 15px;
    font-size: 2em;
    font-weight: 100;
    line-height: 0; }
    li .listitem-right svg {
      transform: rotate(180deg); }
  li .name {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0; }</style>
