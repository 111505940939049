<script>
	import { fade } from 'svelte/transition';
	import { isLoading } from '../store/app.js';
</script>


{#if $isLoading}
	<div class="loading" transition:fade="{{ duration: 200 }}">
		<div class="spinner">
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
				<circle cx="50" cy="50" r="0" fill="none" stroke="#ffffff" stroke-width="18">
					<animate attributeName="r" repeatCount="indefinite" dur="1.25s" values="0;16" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.625s"></animate>
					<animate attributeName="opacity" repeatCount="indefinite" dur="1.25s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.625s"></animate>
				</circle>
				<circle cx="50" cy="50" r="0" fill="none" stroke="#ffffff" stroke-width="18">
					<animate attributeName="r" repeatCount="indefinite" dur="1.25s" values="0;16" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline"></animate>
					<animate attributeName="opacity" repeatCount="indefinite" dur="1.25s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline"></animate>
				</circle>
			</svg>
		</div>
	</div>
{/if}


<style lang="scss">.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  margin-left: auto;
  margin-right: -10px; }

.spinner {
  width: 50px;
  height: 50px;
  color: #fff;
  border-radius: 8px; }</style>
